export const editorial_2024 = [
    {
        sy : [
            {
                problem_name: "Library Book Hunt",
                problem_statement: "https://www.hackerrank.com/contests/codequest-5-0-junior/challenges/library-book-hunt",
            },
            {
                problem_name: "Kunal's Dilemma",
                problem_statement: "https://www.hackerrank.com/contests/codequest-5-0-junior/challenges/kunals-dilemma",
            },
            {
            problem_name: "The equal pizza pact",
            problem_statement: "https://www.hackerrank.com/contests/codequest-5-0-junior/challenges/the-equal-pizza-pact",
            },
        ],
        
        ty : [
            {
                problem_name: "Boat Club Feast",
                problem_statement: "https://www.hackerrank.com/contests/codequest-5-0-sr-ty/challenges/boat-club-feast",
            },
            {
                problem_name: "Amanda the Fisher Girl",
                problem_statement: "https://www.hackerrank.com/contests/codequest-5-0-sr-ty/challenges/amanda-the-fisher-girl",
            },
            {
            problem_name: "Battle of the Bots",
            problem_statement: "https://www.hackerrank.com/contests/codequest-5-0-sr-ty/challenges/battle-of-the-bots",
            },
            {
                problem_name: "The great divide",
                problem_statement: "https://www.hackerrank.com/contests/codequest-5-0-sr-ty/challenges/the-great-divide-coep",
            },
            {
            problem_name: "Goku's Algorythmia Expedition",
            problem_statement: "https://www.hackerrank.com/contests/codequest-5-0-sr-ty/challenges/gokus-algorythmia-expedition",
            },
        ],
        
    },
]

export default editorial_2024; 