import React, { useEffect, useState } from "react";
import Navbar from "../layout/Navbar";
import Footer from "../layout/Footer";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import CodequestRank from "../components/CodequestRanks";
/*import Button from "@material-ui/core/Button";*/
/*import useMediaQuery from "@material-ui/core/useMediaQuery";*/

// import Leaderboard from "../components/Leaderboard";
import Terminal from "../components/Terminal";
import Counter from "../components/Counter";
import inspiron_poster from "../assets/img/gallery/inspiron_poster.png"

import SectionHeading from "../components/SectionHeading";
// import { data as PlacementData } from "../assets/placements-data/PlacementsData"
import {
  makeStyles,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
} from "@material-ui/core";
import { backGround } from "../layout/customStyle";
import RoomIcon from "@material-ui/icons/Room";
import MailIcon from "@material-ui/icons/Mail";
import Leaderboard from "../components/Leaderboard";
import HeroHeading from "../components/HeroHeading";
import poster from "../assets/img/gallery/Inspiron.png";
import Editorial from "../components/Editorial24";
import Certificates from "../components/Certificates";
import { Link } from "react-router-dom";
//import { fontWeight } from "@mui/system";
import InspironCertificates from "../components/InspironCertificates";
import CodeQuest4_Winners from "../assets/img/gallery/CodeQuest4/CodeQuest4.0_Winners.jpeg"
import CodeQuest5_poster from "../assets/img/gallery/CodeQuest5/Codequest_poster.jpg"
import CodeQuest5_dates from "../assets/img/gallery/CodeQuest5/Codequest_dates.jpg"
import mira_session from "../assets/img/gallery/mira_session.jpg"
import Confetti from 'react-confetti';
const useStyles = makeStyles((theme) => ({

  container: {
    textAlign: 'center',
    marginTop: '3em',

  },
  pageTitle: {
    color: 'white',
    marginBottom: '.5em',
  },
  centeredImage: {
    display: 'block',
    margin: '0 auto',
  },

  aboutWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // backgroundColor: "#00000097",
    backgroundImage:
      "linear-gradient(#00000000, #000000bb 10%, #000000bb 89%, #00000000 100%)",
    padding: "3em 1.5em",
  },
  aboutContainer: {
    maxWidth: "1000px",
  },
  aboutText: {
    color: "#7b909c",
    fontSize: "1.4rem",
    marginLeft: "1.2rem",
    // fontFamily: "monospace",
  },
  anchorTag: {
    color: "#b2bbbd",
    textDecoration: "none",
    fontWeight: "bolder",
    "&:hover": {
      textDecoration: "none",
      color: "#fff",
    },
  },
  upcomingImage: {
    width: "35%",
    height: "90vh",
    border: "10px solid #ffffff",
    borderRadius: "5px",
  },
}));

const downloadBrochure = () => {
  const link = document.createElement("a");
  link.download = `CodeQuest4.0 Brochure.pdf`;
  link.href = process.env.PUBLIC_URL + "/CodeQuest4.0 Brochure.pdf";
  link.click();
}

const HomePage = () => {
  const classes = useStyles();
  const [justify, setJustify] = useState(["center", "center"]);
  useEffect(() => {
    let w =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    if (w < 1280) {
      setJustify(["center", "center"]);
    } else {
      setJustify(["flex-end", "flex-start"]);
    }
  }, []);
  /*const matches = useMediaQuery("(min-width:600px)");*/

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  return (
    <div style={backGround}>
      <Navbar home={true} />

      

      <HeroHeading />
      
      <div className={classes.container}>
      <h1 className={classes.pageTitle}>Inspiron 4.0 : 29th-30th March 2025</h1>

  <a href="https://csi.coep.org.in/hackathon-25/" target="_blank" rel="noopener noreferrer">
  <img className={classes.centeredImage} src={inspiron_poster} alt="Description of the image" style={{ maxWidth: "70%", marginBottom: "50px" }} />
    </a>
</div>
      {/* <div className={classes.container}>
        <h1 className={classes.pageTitle}>Codequest 5.0 : Crack the Code, Conquer the Quest</h1>
        <img className={classes.centeredImage} src={CodeQuest5_poster} alt="Description of the image" style={{ maxWidth: "65%" , marginBottom: "50px"}} />
      </div> */}
      <CodequestRank></CodequestRank>
      <Editorial></Editorial>

      {/* <div className={classes.container}>
        <h1 className={classes.pageTitle}>CodeQuest 5.0 : Timeline</h1>
        <img className={classes.centeredImage} src={CodeQuest5_dates} alt="Description of the image" style={{ maxWidth: "65%" }} />
      </div> */}

      {/* <Confetti
  width={window.innerWidth}
  height={window.innerHeight}
  numberOfPieces={300}
  recycle={false}
  friction={0.99}
/> */}


      {/* <center>
      <Typography
          variant="overline"
          style={{ color: "white", fontSize: "1.5em" }}
        >
          CODEQUEST 4.0 : EDITORIAL
        </Typography>
        </center> */}
      {/* <SectionHeading title = "CODEQUEST 4.0 : EDITORIAL"/>
      <Editorial /> */}
      {/* <Leaderboard /> */}

      {/* <SectionHeading title="CSI Student Membership Form for FY Computer COEP"/>
      
<Grid
  container
  direction={`${matches ? `row` : `column`}`}
  justifyContent="center"
  alignItems="center"
>
  {!matches &&
  (<>
   <Grid item>
<Button color="primary" variant="contained" style={{marginBottom:"16px",wordWrap:"break-word",lineHeight:"2.5em",fontSize:"1rem"}} > Register for CSI membership </Button>
 
  
 

  
  </Grid>
  <Grid item>
  <Button color="primary" variant="contained" style={{marginTop:"16px",wordWrap:"break-word",lineHeight:"2.5em",fontSize:"1rem"}}>Download Brochure</Button>
 
  </Grid></>)}
  {matches &&
  (<>
   <Grid item>
<Button color="primary" variant="contained" style={{marginRight:"16px",wordWrap:"break-word",lineHeight:"2.5em",fontSize:"1rem"}} > Register for CSI membership </Button>
 
  
 

  
  </Grid>
  <Grid item>
  <Button color="primary" variant="contained" style={{marginLeft:"16px",wordWrap:"break-word",lineHeight:"2.5em",fontSize:"1rem"}}>Download Brochure</Button>
 
  </Grid></>)}
  

 

  </Grid>*/}

      {/*  <ButtonGroup
            variant="contained"
            size="large"
            className="btngrp"
            color="primary"
            orientation={`${matches ? `horizontal` : `vertical`}`}
          >
            <Button>Overview</Button>
            <Button>Features</Button>
           
  </ButtonGroup>*/}

      {/*<SectionHeading title="Azadi Ka Amrit Mahotsav" />*/}
      {/*<center style={{ marginBottom: "1.5em" }}>
        <i>
          <Typography variant="h5" style={{ color: "white", marginBottom: "1em"}}>
            "Elixir of the Energy of Freedom"
          </Typography>
          <Typography variant="h5" style={{ color: "white" }}>
          Freedom is a precious gift of our freedom fighters, Let's celebrate this AZADI KA AMRIT MAHOTSAV TOGETHER.
          </Typography>
        </i>
</center>*/}
      <div
        style={{
          marginTop: "1rem",
        }}
      >
        {/*}   <Grid container justify="center" alignItems="center" direction="row">
        <Grid item xs={12} md={6} className="p-4">
          <img src={poster} alt="poster" />
        </Grid>
      </Grid>  */}


        {/* <CodequestRank /> */}


        {/* <SectionHeading title="Code To Compete '23" />
        <div className="d-flex justify-content-center mt-2 ">
          <img
            src={require("../assets/img/recent-news/ctc_event.jpg")}
            alt="contributors"
            className={classes.upcomingImage}
          />
        </div> */}
        {/* <SectionHeading title="CODEQUEST 4.0: CERTIFICATES" />
        <Certificates />
        <Grid container justify="center" alignItems="center" direction="row">
          <Grid item sm={12} md={6}>
            <SectionHeading title="Tip of the Day" />
            <Terminal />
          </Grid>
        </Grid> */}
      </div>

      <div
        style={{
          marginTop: "1rem",
        }}
      ></div>

      {/* <SectionHeading title="DOWNLOAD INSPIRON 2.0 CERTIFICATES" /> */}

      {/* <center>
          <Button
            variant="contained"
            color="primary"
            style={{
              padding: "1% 2% 1% 2%",
              marginTop:"2rem",
              fontWeight:"bold",
              fontSize:"1rem"
              // fontFamily: "Gotham",
            }}
            onClick={() => openInNewTab('https://csi.coep.org.in/hackathon-23/#/')}
          >
            <Link
              style={{
               textDecoration: "none",
                color: "white"
              }}
            >
             VISIT WEBSITE <ArrowRightAltIcon/>
            </Link>
          </Button>
          </center> */}

      {/* INSPIRON CERTIFICATES */}
      {/* <InspironCertificates /> */}

      {/* <center>
          <Button
            variant="contained"
            color="primary"
            style={{
              padding: "1% 2% 1% 2%",
              marginTop:"2rem",
              fontWeight:"bold",
              fontSize:"1rem"
              // fontFamily: "Gotham",
            }}
          >
            <Link
              to="/eventPage/2022-23"
              style={{
               textDecoration: "none",
                color: "white"
              }}
            >
             FURTHER DETAILS <ArrowRightAltIcon/>
            </Link>
          </Button>
          </center> */}
      {/* <center style={{ marginBottom: "1.5em",marginTop:"8%" }}>
        
        <Typography variant="h4" style={{ color: "white" }}>
          INSPIRON FINALISTS
        </Typography>
      
    </center> */}

      {/* <SectionHeading title = "WINNERS OF CODEQUEST 3.0"/>
       <Grid container justify="center" alignItems="center" direction="row">
        <Grid item xs={12} md={6} className="p-4">
          <img src={poster} alt="poster" />
        </Grid>
      </Grid>
      <SectionHeading title="CODEQUEST 3.0: CERTIFICATES"/>
      <Certificates /> */}

      <Grid className="mt-5" justify="center" container>
        <Counter />
      </Grid>

      <SectionHeading title="About Us" />
      <div className={classes.aboutWrapper}>
        <div className={classes.aboutContainer}>
          <Typography
            align="justify"
            variant="body2"
            component="p"
            paragraph={true}
            className={classes.aboutText}
          >
            The Computer Society of India (CSI) Student Chapter of{" "}
            <a
              href="https://www.coep.org.in/"
              target="_blank"
              className={classes.anchorTag}
              rel="noopener noreferrer"
            >
              College of Engineering Pune (COEP){"  Tech"}
            </a>
            , established in October 2018 is an active student organization
            which organizes a number of technical activities including
            workshops, competitions, technical symposiums, guest lectures etc.
            for its student members. Under the guidance of{" "}
            <a
              href="https://www.coep.org.in/departments/computerit/aboutdep"
              target="_blank"
              className={classes.anchorTag}
              rel="noopener noreferrer"
            >
              Department of Computer Science and Engineering COEP Tech,{" "}
            </a>
            the student chapter has over 300 members and is run by a Core Team
            and faculty from the department.
            <a
              href="https://csi.coep.org.in/"
              className={classes.anchorTag}
              rel="noopener noreferrer"
            >
              CSI COEP Tech Student Chapter{" "}
            </a>
            gives students the opportunity to grow in the field of IT.
          </Typography>
          <Typography
            align="justify"
            variant="body2"
            component="p"
            paragraph={true}
            className={classes.aboutText}
          >
            Established in 1965, the Computer Society of India, or CSI is the
            first and largest body of computer professionals in India. It is a
            non-profit professional meet to exchange views and information learn
            and share ideas regarding advancement of theory and practice of
            Computer Engineering and Technology Systems, Science and
            Engineering, Information Processing and many more. The national body
            now has 72 chapters across India, 512 student branches, and over
            110000 members, including noted IT industry leaders, scientists, and
            dedicated mathematicians.
          </Typography>

          <Typography
            align="justify"
            variant="body2"
            component="p"
            paragraph={true}
            className={classes.aboutText}
          >
            CSI COEP Tech Student Chapter regularly organizes workshops,
            seminars conventions, and technical talks for the benefit of
            professionals and users of IT apart from conducting development
            programs for professionals, researchers and students.
          </Typography>

          <Typography
            align="justify"
            variant="body2"
            component="p"
            paragraph={true}
            className={classes.aboutText}
          >
            It works closely with other industry associations, government bodies
            and academia to ensure that the benefits of IT advancement
            ultimately percolate down to every single citizen of India.
          </Typography>

          <Typography
            align="justify"
            variant="body2"
            component="p"
            paragraph={true}
            className={classes.aboutText}
          >
            Finally, CSI COEP Tech facilitates industry-academia interaction
            through CIO meets, Professors’ meets. CSI offers professional
            counselling through various events, conferences, symposia to student
            members of their well-established network of Student Branches.
          </Typography>
        </div>
      </div>

      <SectionHeading title="Contact Us" />
      <Grid
        container
        justify="center"
        alignItems="center"
        style={{ marginBottom: "8rem" }}
      >
        <Grid item md={6} xs={10}>
          <Grid container justify={justify[0]}>
            <Card
              style={{
                height: 350,
                width: 450,
                background: "rgba(0,0,0,0.5)",
                textAlign: "center",
                color: "white",
              }}
            >
              <CardContent>
                <RoomIcon />
                <br />
                <br />
                Department of Computer Science and Engineering,
                <br />
                COEP Technological University (COEP Tech),
                <br />
                Wellesley Road,
                <br />
                Shivajinagar, Pune - 411005
                <br />
                <br />
                <MailIcon />
                <br />
                <br />
                csi.comp@coeptech.ac.in
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid item md={6} xs={10}>
          <Grid container justify={justify[1]}>
            <iframe
              title="MAP"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3782.9612579422947!2d73.8532431!3d18.5306528!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c0883c8de109%3A0x2cc01ddd19f7cfa4!2sDepartment+Of+Computer+Engineering+And+Information+Technology!5e0!3m2!1sen!2sin!4v1539196910707"
              width={450}
              height={350}
              frameBorder={0}
              allowFullScreen
            />
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </div>
  );
};

export default HomePage;
